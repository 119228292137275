
.custom-gutter-horizontal {
    padding: 0 1px;
    height: 100%;
    background: rgb(35, 35, 35);
  }
  .custom-gutter-horizontal:hover {
    cursor: col-resize;
  }
  
  .custom-dragger-horizontal {
    display: none;
  }


  